<template>
  <div class="form-group">
    <v-select
      :class="{ 'ndc-search-results': true, 'has-selection': value }"
      v-model="inputVal"
      label="ndc"
      :options="options"
      @search="onSearch"
      :filterable="false"
      :clearSearchOnBlur="clearSearchOnBlur"
      :clearSearchOnSelect="false"
      @option:selecting="optionSelected"
    >
      <template slot="option" slot-scope="option">
        <div>{{ option.ndc }}: {{ option.ndcDescription }}</div>
        <div>
          <span
            >USC: <span v-if="option.drugUsc">{{ option.drugUsc }}</span
            ><span v-else>N/A</span></span
          >
          <span> | </span>
          <span
            >AHFS:
            <span v-if="option.drugAhfsClass">{{ option.drugAhfsClass }}</span
            ><span v-else>N/A</span></span
          >
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected">
          {{ option.ndc }}: {{ option.ndcDescription }}
        </div>
      </template>
      <template #spinner="{ loading }">
        <div v-if="loading">
          <loader loaderWidth="50px" class="ndc-lookup-loader">
            <template #offscreenText>Loading search results.</template>
          </loader>
        </div>
      </template>
      <div
        slot="list-header"
        v-if="searchResultsTerm != ''"
        class="fs-14 rfs-m-1-t rfs-m-1-b rfs-m-1-l"
      >
        Results for "{{ searchResultsTerm }}"
      </div>
      <div slot="list-footer" class="pagination rfs-p-3">
        <!-- TODO: Accessiblity for paging -->
        <button
          class="btn btn-sm rfs-m-2-r"
          @click="onPrev"
          :disabled="!hasPrevPage"
        >
          Prev
        </button>
        <button class="btn btn-sm" @click="onNext" :disabled="!hasNextPage">
          Next
        </button>
        <span class="color-white"> {{ totalrecords }} results</span>
      </div>
    </v-select>
    <label class="control-label" v-html="fieldLabel"></label><i class="bar"></i>
  </div>
</template>

<script>
  import vSelect from 'vue-select'; // https://vue-select.org/
  import debounce from 'lodash/debounce';

  export default {
    name: 'NdcLookup',
    props: ['value', 'optionLabel', 'fieldLabel', 'store'],
    data() {
      return {
        options: [],
        loading: false,
        offset: 0,
        pages: 0,
        limit: 10,
        totalrecords: 0,
        hasPrevPage: false,
        hasNextPage: false,
        query: null,
        searchResultsTerm: ''
      };
    },
    components: {
      vSelect
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set() {
          // The value emit happens in optionSelected
          //  this is because the model only runs when a different value is selected
          //  while optionSelected() runs every selection
        }
      }
    },
    methods: {
      clearSearchOnBlur() {
        return false;
      },
      optionSelected(val) {
        this.$emit('input', val);
      },
      onSearch(search, loading) {
        if (search != '') {
          loading(true);
          this.loading = loading;
          this.search(search, this, this.loading);
        }
      },
      onNext() {
        this.offset += this.limit;
        this.onSearch(this.query, this.loading);
      },
      onPrev() {
        this.offset -= this.limit;
        this.onSearch(this.query, this.loading);
      },
      search: debounce((search, _this, loading) => {
        if (_this.query !== search) {
          _this.query = search;
          _this.offset = 0;
        }
        _this
          .$http({
            url: '/data-service/ndcsv2/',
            params: {
              searchString: search,
              limit: _this.limit,
              offset: _this.offset
            }
          })
          .then((res) => {
            _this.searchResultsTerm = search;
            _this.options = res.data;
            _this.error = false;
            loading(false);
            _this.loading = loading;
            _this.totalrecords = res.headers.totalrecords;
            _this.pages = Math.ceil(
              parseInt(res.headers.totalrecords) / _this.limit
            );
            _this.hasPrevPage =
              _this.pages !== 0 && _this.offset / _this.limit !== 0;
            _this.hasNextPage =
              _this.pages !== 0 && _this.pages > _this.offset / _this.limit + 1;
          });
      }, 350)
    }
  };
</script>

<style scoped>
  .ndc-lookup-loader {
    position: absolute;
    top: 0;
    right: 6px;
  }
</style>

<style>
  .ndc-search-results .vs__dropdown-menu {
    max-height: 600px;
  }
</style>
