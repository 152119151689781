export const calculateJQXTableHeight = function (minHeight, heightOffset) {
  const bodyRect = document.body.getBoundingClientRect();
  const totalOffset = bodyRect.top;

  // Default offset of 200px
  let height =
    window.innerHeight -
    totalOffset -
    (heightOffset != null ? heightOffset : 264);

  // Default min height of 300px
  if (minHeight != null) {
    if (height > minHeight) {
      return height;
    } else {
      return minHeight;
    }
  } else {
    if (height > 300) {
      return height;
    } else {
      return 300;
    }
  }
};
