<template>
  <!-- relative position keeps mimai loader in the middle -->
  <div data-var-rep-report style="position: relative" class="var-rep-report">
    <loader ref="miamiLoader" fullScreen />
    <JqxTreeGrid
      ref="myGrid"
      :width="tableWidth"
      :sortable="sortable"
      :class="{ sortable: sortable }"
      selectionMode="none"
      :enableBrowserSelection="true"
      :source="dataAdapter"
      :columns="columns"
      :columnGroups="columnGroups"
      :columnsresize="true"
      :ready="tableReady"
      @sort="onSort($event)"
      @rowExpand="onRowExpand($event)"
      @rowCollapse="onRowCollapse($event)"
      :height="tableHeight"
    ></JqxTreeGrid>
  </div>
</template>

<script>
  import JqxTreeGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxtreegrid.vue';
  import tableStore from '../../store/modules/variancereports/table';
  import { calculateJQXTableHeight } from '@/js/utilities/calculateJQXTableHeight.js';

  export default {
    name: 'app',
    components: {
      JqxTreeGrid
    },
    computed: {
      route() {
        return this.$route;
      },
      routePath() {
        return this.route.path;
      },
      routeName() {
        return this.$route.name;
      },
      isDrugPricingReport() {
        return (
          this.routeName == 'DrugPricing'
        );
      },
      storePath() {
        return 'vr/table_data' + this.routePath;
      },
      apiPath() {
        return this.route.meta.apiEndpoint;
      },
      tableWidth() {
        return '100%';
      },
      tableHeight: function () {
        let h2Rect = document
          .getElementsByTagName('header')[0]
          .getBoundingClientRect();
        let filtersRect = document
          .getElementsByClassName('grid')[0]
          .getBoundingClientRect();
        let ndcLookupRect = document
          .getElementsByClassName('grid')[1]
          .getBoundingClientRect();
        let totalOffset = h2Rect.top + filtersRect.top + ndcLookupRect.top;

        return calculateJQXTableHeight(500, totalOffset - 44);
      },
      tableData: {
        get() {
          return this.$store.getters[this.storePath + '/getValue'];
        },
        set(value) {
          return this.$store.commit(this.storePath + '/setValue', value);
        }
      },
      hospitalExists() {
        return this.$store.hasModule('vr/hospital_segment');
      },
      hospitalSegment() {
        return this.$store.getters['vr/hospital_segment/getValue'];
      },
      indexSegment() {
        return this.$store.getters['vr/index_segment/getValue'];
      },
      accountTypes() {
        return this.$store.getters['vr/account_types/getValue'];
      },
      dateRange() {
        return this.$store.getters['vr/date_range/getValue'];
      },
      codeType() {
        return this.$store.getters['vr/code_type/getValue'];
      },
      ndcLookup() {
        return JSON.parse(this.$store.getters['vr/ndc_lookup/getValue']);
      },
      apiParams() {
        var params = {
          comparisonIndex: this.indexSegment.value
            ? this.indexSegment.value
            : '',
          endDate: this.dateRange.end,
          startDate: this.dateRange.start,
          useHierarchy: this.codeType
        };

        if (this.hospitalExists) {
          const hsptlSegments = this.hospitalSegment;
          params.hospitalIds =
            hsptlSegments !== undefined &&
            hsptlSegments !== null &&
            hsptlSegments.length > 0
              ? this.hospitalSegment
                  .map((hsptl) => hsptl.value)
                  .reduce((a, r) => a + ',' + r)
              : '';
        }

        if (this.isDrugPricingReport) {
          const atArray = this.accountTypes.map((a) => {
            return a.value;
          });
          params.accountType =
            atArray.length === 0
              ? ['GPO', '340B', 'WAC'].join()
              : Array.isArray(atArray)
              ? atArray.join()
              : atArray;
        }

        return params;
      },
      columns: function () {
        if (this.routeName === 'Summary') {
          return [
            {
              text: 'Code',
              datafield: 'd_code',
              pinned: true,
              width: '200'
            },
            {
              text: 'Category',
              datafield: 'd_category',
              pinned: true,
              classname: 'code-col',
              width: '300'
            },
            {
              text: '<span id="c_totalSpend" title="Total cost of all NDCs purchased from the wholesaler or manually entered in the class/subclass for your facility">Total Spend</span>',
              datafield: 'c_totalSpend',
              columngroup: 'Client',
              cellsalign: 'right',
              align: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '150'
            },
            {
              text: '<span id="c_averageCostPerNdc" title="Average cost, based on Index, of all NDCs in the class/subclass for your facility">Avg Cost per NDC</span>',
              datafield: 'c_averageCostPerNdc',
              columngroup: 'Client',
              cellsalign: 'right',
              align: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '150'
            },
            {
              text: '<span id="c_averageDrugCostPerIpDay" title="Total cost of drugs dispensed for the category divided by the total number of inpatient days">Avg Drug Cost per IP Day</span>',
              datafield: 'c_averageDrugCostPerIpDay',
              columngroup: 'Client',
              cellsalign: 'right',
              align: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '200'
            },
            {
              text: '<span id="c_averageLengthOfStay" title="Total inpatient days divided by total inpatient discharges for patients receiving at least one dose of the selected class/subclass or NDC">ALOS (Days)</span>',
              datafield: 'c_averageLengthOfStay',
              columngroup: 'Client',
              cellsalign: 'right',
              align: 'right',
              cellsformat: 'd2',
              rendered: this.tooltiprenderer,
              width: '150'
            },
            {
              text: '<span id="c_readmissionsRate" title="30 Day All Cause Readmissions divided by total inpatient discharges as a percentage">Readmissions Rate</span>',
              datafield: 'c_readmissionsRate',
              columngroup: 'Client',
              cellsalign: 'right',
              align: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '150'
            }
          ];
        } else if (this.routeName === 'DrugPricing') {
          return [
            {
              text: 'Code',
              datafield: 'd_code',
              pinned: true,
              width: '200'
            },
            {
              text: 'Category',
              datafield: 'd_category',
              pinned: true,
              width: '400'
            },

            {
              text: '<span id="c_percentOfTotal" title="Percentage of purchases based on account types selected (all selected this will be 100%)">% of Total Purchased</span>',
              datafield: 'c_percentOfTotal',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '200'
            },
            {
              text: '<span id="c_ndcUnitsPurchased" title="Total number of NDC units purchased">NDC Units Purchased</span>',
              datafield: 'c_ndcUnitsPurchased',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '200'
            },
            {
              text: '<span id="c_totalDrugSpend" title="Total amount of drug(s) purchased in a class/subclass or NDC for the account(s) type selected">Total Drug Spend</span>',
              datafield: 'c_totalDrugSpend',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '200'
            },
            {
              text: '<span id="c_weightedAveragePerNdc" title="Average price of all medications/drugs purchased in the class/subclass or NDC calculated by dividing the total drug spend by the NDC units purchased">Wt Avg Price per NDC</span>',
              datafield: 'c_weightedAveragePerNdc',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '200'
            },

            {
              text: '<span id="i_percentOfTotal" title="Percentage of purchases based on account types selected (all selected this will be 100%)">% of Total Purchased</span>',
              datafield: 'i_percentOfTotal',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '200'
            },
            {
              text: '<span id="i_weightedAveragePerNdc" title="Average price of all medications/drugs purchased in the class/subclass or NDC calculated by dividing the total drug spend by the NDC units purchased. The Index includes NDCs that your facility does not purchase or use">Wt Avg Price per NDC</span>',
              datafield: 'i_weightedAveragePerNdc',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '200'
            }
          ];
        } else if (this.routeName === 'CostInpatientPerDay') {
          return [
            {
              text: 'Code',
              datafield: 'd_code',
              pinned: true,
              width: '200'
            },
            {
              text: 'Category',
              datafield: 'd_category',
              pinned: true,
              width: '300'
            },
            {
              text: '<span id="c_ipDischarges" title="Total number of inpatient discharges for patients receiving at least one dose of the selected class/subclass/NDC">IP Discharges</span>',
              datafield: 'c_ipDischarges',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '105'
            },
            {
              text: '<span id="c_days" title="Total number of inpatient days for patients receiving at least one dose of the selected class/subclass/NDC">Days</span>',
              datafield: 'c_days',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '60'
            },
            {
              text: '<span id="c_totalCostOfDrugsUsed" title="The additive total of the drugs purchased for the selected class/subclass or NDC">Total Cost of Drugs Used</span>',
              datafield: 'c_totalCostOfDrugsUsed',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '180'
            },
            {
              text: '<span id="c_avgDrugCostPerDay" title="The total cost of drugs used divided by the total number of inpatient days for the selected class/subclass or NDC">Avg Drug Cost per Day</span>',
              datafield: 'c_avgDrugCostPerDay',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '170'
            },
            {
              text: '<span id="c_drugCostByTotalCostPerDay" title="As a percentage the Drug cost per day/ hospital cost per inpatient day amount">Drug Cost per Day / Total Cost per Day</span>',
              datafield: 'c_drugCostByTotalCostPerDay',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '280'
            },

            {
              text: '<span id="i_avgDrugCostPerDay" title="The total cost of drugs used divided by the total number of inpatient days for the selected class/subclass or NDC">Avg Drug Cost per Day</span>',
              datafield: 'i_avgDrugCostPerDay',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'c0',
              cellsrenderer: this.negParenDollarRenderer,
              rendered: this.tooltiprenderer,
              width: '170'
            },
            {
              text: '<span id="i_drugCostByTotalCostPerDay" title="As a percentage the Drug cost per day/by the Index cost per inpatient day amount<br/><br/>(each index facility \'cost per inpatient day\' added and divided by the total number of facilities)">Drug Cost per Day / Total Cost per Day</span>',
              datafield: 'i_drugCostByTotalCostPerDay',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '280'
            }
          ];
        } else if (this.routeName === 'AverageLengthOfStay') {
          return [
            {
              text: 'Code',
              datafield: 'd_code',
              pinned: true,
              width: '200'
            },
            {
              text: 'Category',
              datafield: 'd_category',
              pinned: true,
              width: '300'
            },

            {
              text: '<span id="c_ipDischarges" title="Number of Inpatient discharges for patients receiving at least one dose of a drug in the class/subclass or NDC">IP Discharges</span>',
              datafield: 'c_ipDischarges',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '160'
            },
            {
              text: '<span id="c_days" title="Number of days for patients receiving at least one dose of a drug in the class/subclass or NDC">IP Days</span>',
              datafield: 'c_days',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '160'
            },
            {
              text: '<span id="c_averageLengthOfStay" title="Average Length of Stay for patients receiving at least one dose of a drug in the class/subclass or NDC">ALOS</span>',
              datafield: 'c_averageLengthOfStay',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'd2',
              rendered: this.tooltiprenderer,
              width: '160'
            },

            {
              text: '<span id="i_averageLengthOfStay" title="Average Length of Stay for patients receiving at least one dose of a drug in the class/subclass or NDC">ALOS</span>',
              datafield: 'i_averageLengthOfStay',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'd2',
              rendered: this.tooltiprenderer,
              width: '160'
            }
          ];
        } else if (this.routeName === 'Readmissions') {
          return [
            {
              text: 'Code',
              datafield: 'd_code',
              pinned: true,
              width: '200'
            },
            {
              text: 'Category',
              datafield: 'd_category',
              pinned: true,
              width: '300'
            },

            {
              text: '<span id="c_ipDischarges" title="Total number of inpatient discharges for the selected class/subclass or NDC">IP Discharges</span>',
              datafield: 'c_ipDischarges',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '120'
            },
            {
              text: '<span id="c_averageLengthOfStay" title="The number of inpatient days divided by the number of inpatient discharges for the selected class/subclass or NDC">ALOS</span>',
              datafield: 'c_averageLengthOfStay',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'd2',
              rendered: this.tooltiprenderer,
              width: '80'
            },
            {
              text: '<span id="c_days" title="Number of days for patients receiving at least one dose of a drug in the class/subclass or NDC">IP Days</span>',
              datafield: 'c_days',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '80'
            },
            {
              text: '<span id="c_readmissions" title="The total number of 30 day readmissions for the selected class/subclass or NDC">Readmissions</span>',
              datafield: 'c_readmissions',
              columngroup: 'Client',
              cellsalign: 'right',
              rendered: this.tooltiprenderer,
              width: '120'
            },
            {
              text: '<span id="c_readmissionRate" title="As a percentage the number of readmissions divided by the number of inpatient discharges for the selected class/subclass or NDC">Readmissions Rate</span>',
              datafield: 'c_readmissionRate',
              columngroup: 'Client',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '140'
            },

            {
              text: '<span id="i_readmissionRate" title="As a percentage the total number of readmissions divided by the number of inpatient discharges for the selected class/subclass or NDC">Readmissions Rate</span>',
              datafield: 'i_readmissionRate',
              columngroup: 'Index',
              cellsalign: 'right',
              cellsformat: 'p0',
              rendered: this.tooltiprenderer,
              width: '140'
            }
          ];
        }

        return [];
      },
      ndcNotFoundMessage() {
        return (
          'This NDC was not ' +
          this.ndcActionByRoute +
          ' by you or the index during the specified date range. Please expand your time frame or search for a different NDC.'
        );
      },
      ndcActionByRoute() {
        if (this.isDrugPricingReport) {
          return 'purchased';
        } else {
          return 'dispensed';
        }
      }
    },
    data: function () {
      return {
        sortable: true,
        getWidth: '100%',
        // eslint-disable-next-line no-undef, new-cap
        dataAdapter: new jqx.dataAdapter(this.source),
        columnGroups: [
          {
            name: 'Client',
            text: 'Your Data',
            align: 'center'
          },
          {
            name: 'Index',
            text: 'Real World Data',
            align: 'center'
          }
        ],
        emptyChild: [
          {
            placeholder: true
          }
        ],
        currentSort: {
          field: null,
          direction: null
        },
        currentAutoExpanding: {
          level: null,
          ndcData: null
        },
        watchers: {
          unwatchRoute: null,
          unwatchHospitalSegment: null,
          unwatchIndexSegment: null,
          unwatchAccountType: null,
          unwatchDateRange: null,
          unwatchCodeType: null,
          unwatchNdcLookup: null
        },
        tableTips: []
      };
    },
    methods: {
      createStore() {
        // Register the new modules dynamically
        if (!this.$store.hasModule(this.storePath)) {
          this.$store.registerModule(this.storePath, tableStore);
        }
      },
      clearStore() {
        this.tableData = false;
      },
      openLoader() {
        this.$refs.miamiLoader.openLoader();
      },
      closeLoader() {
        this.$refs.miamiLoader.closeLoader();
      },
      updateTableData(d) {
        this.source.localdata = d;
        this.$refs.myGrid.updateBoundData();
      },
      tableReady: function () {
        // Prepare the initial sort
        this.setInitialSort();

        // Get table data
        this.reloadTableData();
      },
      setInitialSort() {
        if (this.routeName === 'Summary') {
          this.currentSort.field = 'c_totalSpend';
        } else if (this.isDrugPricingReport) {
          this.currentSort.field = 'c_totalDrugSpend';
        } else if (this.routeName === 'CostInpatientPerDay') {
          this.currentSort.field = 'v_avgDrugCostPerDayValue';
        } else if (this.routeName === 'AverageLengthOfStay') {
          this.currentSort.field = 'v_averageLengthOfStayValue';
        } else if (this.routeName === 'Readmissions') {
          this.currentSort.field = 'v_readmissionRateValue';
        }

        this.currentSort.direction = false;
      },
      reloadTableData() {
        if (!this.tableData) {
          this.getTableData();
        } else {
          this.updateTableData(this.tableData);
          this.$refs.myGrid.sortBy(
            this.currentSort.field,
            this.currentSort.direction
          );

          this.sortTable();
        }
      },
      // This one handles top-level table reloading
      getTableData() {
        // If we have no indexes and no hospitals, don't request data
        if (this.indexSegment.value || this.hospitalExists) {
          if (
            this.hospitalSegment !== undefined &&
            this.hospitalSegment !== null &&
            this.hospitalSegment.length > 0
          ) {
            this.openLoader();

            // Tell the parent to remove the AO whenever we update data
            this.$emit('on-ao-unknown');

            this.$http({
              url: '/data-service/' + this.apiPath + '/',
              params: this.apiParams
            }).then((response) => {
              this.tableData = this.convertDataStructure(response.data);
              this.reloadTableData();
              this.closeLoader();
            });
          }
        }
      },
      convertDataStructure: function (data) {
        var allData = [];

        for (var i = 0; i < data.length; i++) {
          var thisRow = {};

          for (var j in data[i]) {
            if (j === 'children') {
              continue;
            }

            // Combine the category and data name to make a unique identifier
            for (var k in data[i][j]) {
              thisRow[j.charAt(0) + '_' + k] = data[i][j][k];
            }
          }

          if (data[i].description.codeType !== 'NDC') {
            thisRow.children = this.emptyChild;
            thisRow.expanded = false;
          }

          allData.push(thisRow);
        }

        return allData;
      },
      tooltiprenderer: function (element) {
        // Safety checks
        // The container div
        if (!element[0]) return;
        // The JQW span
        if (!element[0].childNodes[0]) return;
        // Our title span
        if (!element[0].childNodes[0].childNodes[0]) return;

        let containerSpan = element[0].childNodes[0].childNodes[0];
        let id = 'tooltipContainer_' + containerSpan.id;
        element[0].id = id;

        let content = containerSpan.getAttribute('title');
        setTimeout((_) =>
          this.tooltipJqwBind(element[0], {
            value: content
          })
        );
        this.tableTips.push(element[0]);

        element[0].childNodes[0].childNodes[0].removeAttribute('title');
      },
      tooltipUnbinder() {
        this.tableTips.forEach((tooltip) => {
          this.tooltipJqwUnbind(tooltip);
        });
      },
      redGreenRenderer: function (
        rowid,
        columnfield,
        value,
        rowdata,
        defaulthtml,
        test
      ) {
        return this.redGreenRendererHelper(value, defaulthtml);
      },
      redGreenRendererHelper: function (value, html) {
        if (value <= -15) {
          return '<span class="color-success">' + html + '</span>';
        } else if (value >= 15) {
          return '<span class="color-error">' + html + '</span>';
        }

        return html;
      },
      negParenDollarRenderer: function (
        rowid,
        columnfield,
        value,
        rowdata,
        defaulthtml,
        test
      ) {
        return this.negParenDollarRendererHelper(value, defaulthtml);
      },
      negParenDollarRendererHelper: function (value, html) {
        if (value < 0) {
          return '(' + Math.abs(value) + ')';
        }

        return html;
      },
      complexRenderer: function (
        rowid,
        columnfield,
        value,
        rowdata,
        defaulthtml,
        test
      ) {
        // Complicated renders go here - don't use this if you don't need it

        if (this.isDrugPricingReport) {
          if (columnfield === 'v_weightedAveragePerNdc') {
            const formattedNum = defaulthtml.substr(1);
            return this.redGreenRendererHelper(
              rowdata.v_percentageWeightedAveragePerNdc,
              this.negParenDollarRendererHelper(formattedNum, defaulthtml)
            );
          }
        }
      },
      onRowExpand: function (event) {
        this.openLoader();
        this.rowExpandHelper(
          event.args.row.d_code,
          event.args.row.d_codeType,
          event.args.row
        );
      },
      rowExpandHelper(code, codeType, row) {
        if (
          this.hospitalSegment !== undefined &&
          this.hospitalSegment !== null &&
          this.hospitalSegment.length > 0
        ) {
          const params = this.apiParams;

          // Add expand code
          params.code = code;
          params.codeType = codeType;

          this.$http({
            url: '/data-service/' + this.apiPath + '/',
            params: params
          }).then((response) => {
            let currentData = this.source.localdata;

            const formattedData = this.convertDataStructure(response.data);
            currentData = this.addChildData(formattedData, row, currentData);
            this.updateTableData(currentData);

            // Not auto expanding, just a click
            if (this.currentAutoExpanding.ndcData === null) {
              this.sortTable();
              this.closeLoader();
            } else {
              // This is not the final level
              if (formattedData[0] && formattedData[0].d_codeType !== 'NDC') {
                this.currentAutoExpanding.level++;
                this.getNDCExpansion();
              } else {
                // End the expansion
                let foundNdc = this.currentAutoExpanding.ndcData.ndc;

                let ndcRows = document.querySelectorAll(
                  ".crca-datatable [data-key='" + foundNdc + "']"
                );

                if (ndcRows && ndcRows.length > 0) {
                  // Scroll into view
                  this.$refs.myGrid.ensureRowVisible(
                    this.currentAutoExpanding.ndcData.ndc
                  );

                  // Highlight the NDC
                  ndcRows.forEach(function (ndcRow) {
                    ndcRow.classList.add('highlighted-row');
                  });
                } else {
                  this.$snotify.warning(this.ndcNotFoundMessage);
                  this.$refs.myGrid.collapseAll();
                }

                // Stop the auto expansion process
                this.currentAutoExpanding.ndcData = null;
                this.currentAutoExpanding.level = null;

                this.closeLoader();
              }
            }
          });
        }
      },
      onRowCollapse: function (event) {
        // Have to maintain the version in the store
        const rowCollapsed = event.args.key;
        let currentData = this.source.localdata;
        currentData = this.hideChildData(rowCollapsed, currentData);
        this.updateTableData(currentData);

        this.source.localdata = currentData;
        this.$refs.myGrid.updateBoundData();
      },
      // Recursive function to traverse the table data and add child data to the right place
      addChildData(newData, rowExpanding, currentData) {
        const tCurrentData = currentData;
        const updatedStructure = tCurrentData;

        // For each row in the table
        for (const i in tCurrentData) {
          // If I've found the place for the new data
          if (tCurrentData[i].d_code === rowExpanding.d_code) {
            updatedStructure[i].children = newData;
            updatedStructure[i].expanded = true;
          }
          // If this row has child data, need to check the child table
          else if (
            tCurrentData[i].children &&
            !tCurrentData[i].children.placeholder
          ) {
            updatedStructure[i].children = this.addChildData(
              newData,
              rowExpanding,
              updatedStructure[i].children
            );
          }
        }

        return updatedStructure;
      },
      hideChildData(code, currentData) {
        for (const i in currentData) {
          if (currentData[i].d_code === code) {
            currentData[i].expanded = false;

            // This is not ideal, but helps with UX for now.
            currentData[i].children = this.emptyChild;
            return currentData;
          } else if (
            currentData[i].children &&
            !currentData[i].children.placeholder
          ) {
            currentData[i].children = this.hideChildData(
              code,
              currentData[i].children
            );
          }
        }

        return currentData;
      },
      onSort: function (event) {
        // This "wrapper" function exists to throw away the event parameter instead of passing it to sortTable
        //    this way we don't have to decide if the first parameter is the sort column or an event object
        this.sortTable();
      },
      sortTable(column, direction) {
        if (column) this.currentSort.field = column;
        if (direction) this.currentSort.direction = direction;

        let currentData = this.source.localdata;
        let newData = this.sortTableHelper(currentData);
        this.updateTableData(newData);
      },
      sortTableHelper(data) {
        let sortedData = data.sort(this.varRepSort);

        for (let i = 0; i < sortedData.length; i++) {
          if (sortedData[i].expanded) {
            sortedData[i].children = this.sortTableHelper(
              sortedData[i].children
            );
          }
        }

        return sortedData;
      },
      // Custom sorting function
      varRepSort(a, b) {
        if (a[this.currentSort.field] === b[this.currentSort.field]) {
          return 0;
        }

        if (
          a[this.currentSort.field] === null ||
          typeof a[this.currentSort.field] === 'undefined'
        ) {
          return this.currentSort.direction ? 1 : -1;
        }
        if (
          b[this.currentSort.field] === null ||
          typeof b[this.currentSort.field] === 'undefined'
        ) {
          return this.currentSort.direction ? -1 : 1;
        }

        if (a[this.currentSort.field] > b[this.currentSort.field]) {
          return this.currentSort.direction ? 1 : -1;
        }

        if (b[this.currentSort.field] > a[this.currentSort.field]) {
          return this.currentSort.direction ? -1 : 1;
        }

        return 0;
      },
      // Called when an NDC search result is clicked
      getNDCExpansion() {
        let digitsToKeep = null;
        const level = this.currentAutoExpanding.level;
        let parentCode = null;
        let codeType = null;

        // Code Looking is the USC/AHFS code expected at level level
        if (this.codeType === 'USC') {
          parentCode = this.currentAutoExpanding.ndcData.drugUsc;
          codeType = this.codeType + (level + 2);
          digitsToKeep = parseInt(level) + 2;
        } else if (this.codeType === 'AHFS') {
          parentCode = this.currentAutoExpanding.ndcData.drugAhfsClass;
          codeType = this.codeType + (level + 1) * 2;
          digitsToKeep = (parseInt(level) + 1) * 2;
        }

        if (parentCode) {
          let codeLooking = parentCode.toString().substr(0, digitsToKeep);

          for (let i = 0; i < parentCode.length - digitsToKeep; i++) {
            codeLooking += '0';
          }

          this.openLoader();

          // Now we can just add the child data same as expanding a row
          this.rowExpandHelper(codeLooking, codeType, {
            d_code: codeLooking
          });
        }
        // This means didn't find anything to expand, and we're not at the NDC level
        // meaning the NDC isn't in the table
        else {
          this.$snotify.warning(this.ndcNotFoundMessage);

          this.currentAutoExpanding.level = null;
          this.currentAutoExpanding.ndcData = null;
        }
      }
    },
    mounted() {
      this.watchers.unwatchRoute = this.$store.watch(
        () => this.$route.name,
        () => {
          this.createStore();

          // For now, just clear the store
          this.clearStore();

          this.reloadTableData();
        }
      );

      // Reload table data when filter is changed
      this.watchers.unwatchHospitalSegment = this.$store.watch(
        () => this.$store.getters['vr/hospital_segment/getValue'],
        () => {
          this.getTableData();
        }
      );

      // Reload table data when filter is changed
      this.watchers.unwatchIndexSegment = this.$store.watch(
        () => this.$store.getters['vr/index_segment/getValue'],
        () => {
          this.getTableData();
        }
      );

      // Reload table data when filter is changed
      this.watchers.unwatchAccountType = this.$store.watch(
        () => this.$store.getters['vr/account_types/getValue'],
        () => {
          this.getTableData();
        }
      );
      this.watchers.unwatchDateRange = this.$store.watch(
        () => this.$store.getters['vr/date_range/getValue'],
        (newValue) => {
          if (newValue && newValue.start && newValue.end) {
            this.getTableData();
          }
        }
      );

      // Reload table data when filter is changed
      this.watchers.unwatchCodeType = this.$store.watch(
        () => this.$store.getters['vr/code_type/getValue'],
        () => {
          this.getTableData();
        }
      );

      // Start the NDC expansion process when the NDC search value is changed
      this.watchers.unwatchNdcLookup = this.$store.watch(
        () => this.$store.getters['vr/ndc_lookup/getValue'],
        () => {
          this.currentAutoExpanding.ndcData = this.ndcLookup;
          this.currentAutoExpanding.level = 0;
          this.$refs.myGrid.collapseAll();
          this.getNDCExpansion();
        }
      );
    },
    beforeCreate: function () {
      this.source = {
        datatype: 'json',
        datafields: [
          // Every field in the table data structure needs to be defined here
          // All
          {
            name: 'd_code',
            type: 'string'
          },
          {
            name: 'd_codeType',
            type: 'string'
          },
          {
            name: 'd_category',
            type: 'string'
          },
          {
            name: 'children',
            type: 'array'
          },
          {
            name: 'expanded',
            type: 'bool'
          },

          // Summary
          {
            name: 'c_totalSpend',
            type: 'number'
          },
          {
            name: 'c_averageCostPerNdc',
            type: 'number'
          },
          {
            name: 'c_averageDrugCostPerIpDay',
            type: 'number'
          },
          {
            name: 'c_averageLengthOfStay',
            type: 'number'
          },
          {
            name: 'c_readmissionsRate',
            type: 'number'
          },

          // Drug Pricing
          {
            name: 'c_percentOfTotal',
            type: 'number'
          },
          {
            name: 'c_ndcUnitsPurchased',
            type: 'number'
          },
          {
            name: 'c_totalDrugSpend',
            type: 'number'
          },
          {
            name: 'c_weightedAveragePerNdc',
            type: 'number'
          },
          {
            name: 'i_ndcUnitsPurchased',
            type: 'number'
          },
          {
            name: 'i_percentOfTotal',
            type: 'number'
          },
          {
            name: 'i_totalDrugSpend',
            type: 'number'
          },
          {
            name: 'i_weightedAveragePerNdc',
            type: 'number'
          },

          // Drug Cost IP Day
          {
            name: 'c_ipDischarges',
            type: 'number'
          },
          {
            name: 'c_days',
            type: 'number'
          },
          {
            name: 'c_totalCostOfDrugsUsed',
            type: 'number'
          },
          {
            name: 'c_avgDrugCostPerDay',
            type: 'number'
          },
          {
            name: 'c_drugCostByTotalCostPerDay',
            type: 'number'
          },
          {
            name: 'i_avgDrugCostPerDay',
            type: 'number'
          },
          {
            name: 'i_drugCostByTotalCostPerDay',
            type: 'number'
          },
          {
            name: 'v_avgDrugCostPerDay',
            type: 'number'
          },

          // ALOS
          {
            name: 'c_ipDischarges',
            type: 'number'
          },
          {
            name: 'c_days',
            type: 'number'
          },
          {
            name: 'c_averageLengthOfStay',
            type: 'number'
          },
          {
            name: 'i_averageLengthOfStay',
            type: 'number'
          },

          // Readmissions
          {
            name: 'c_ipDischarges',
            type: 'number'
          },
          {
            name: 'c_averageLengthOfStay',
            type: 'number'
          },
          {
            name: 'c_days',
            type: 'number'
          },
          {
            name: 'c_readmissions',
            type: 'number'
          },
          {
            name: 'c_readmissionRate',
            type: 'number'
          },
          {
            name: 'i_readmissionRate',
            type: 'number'
          }
        ],
        hierarchy: {
          root: 'children'
        },
        id: 'd_code',
        localData: [],
        sort: (column, direction) => {
          this.sortTable(column, direction);
        }
      };
    },
    created() {
      this.createStore();

      // For now, just clear the store
      this.clearStore();
    },
    beforeDestroy() {
      // unwatch so bad things don't leak out
      this.watchers.unwatchRoute();
      this.watchers.unwatchHospitalSegment();
      this.watchers.unwatchIndexSegment();
      this.watchers.unwatchAccountType();
      this.watchers.unwatchDateRange();
      this.watchers.unwatchCodeType();
      this.watchers.unwatchNdcLookup();
      this.tooltipUnbinder();
    }
  };
</script>

<style scoped>
  .var-rep-report >>> .iconscontainer {
    width: 16px !important;
    margin-left: -16px !important;
  }
</style>
