<template>
  <div>
    <div
      class="
        grid
        sm:grid-cols-12
        md:grid-cols-12
        lg:grid-cols-12
        xl:grid-cols-12
        xxl:grid-cols-12
        gap-4
        rfs-m-12-t
      "
    >
      <!-- <div class="flex flex-wrap"> -->
      <date-range-magic
        :value="dateRange"
        :class="selectClass('dateRange')"
        moduleNamespace="vr/date_range"
      />

      <multi-select
        :class="selectClass('accountTypes')"
        v-if="needsAccountTypes"
        v-model="accountTypes"
        :options="atOptions"
        fieldLabel="Account Types"
        :clearable="false"
      />

      <multi-select-checkbox-dropdown
        v-if="needsHospitalSelector"
        :class="selectClass('currentHospital')"
        v-model="hospitalSegment"
        :options="hospitalListOptions"
        optionLabel="text"
        fieldLabel="Hospital"
        :clearable="false"
      />

      <single-select
        :class="selectClass('indexSegment')"
        v-model="indexSegment"
        :options="isOptions"
        optionLabel="text"
        fieldLabel="Real World Data Segment"
        :clearable="false"
      />
      <!-- <index-select class="lg:col-span-3" moduleNamespace="vr/index_segment" /> -->
    </div>
    <div
      class="grid sm:grid-cols-4 lg:grid-cols-10 sm:gap-4 rfs-m-8-t rfs-m-2-b"
    >
      <radio-select
        class="lg:col-span-2"
        v-model="codeType"
        :options="ctOptions"
        asRow
      />
      <ndc-lookup
        fieldLabel="Look Up NDC"
        v-model="ndcLookup"
        data-var-rep-ndc-search-field
        class="lg:col-span-4"
      />
    </div>
    <div class="crca-datatable">
      <VarRepTable :key="$route.name" v-if="readyToLoadTable"></VarRepTable>
    </div>
  </div>
</template>

<script>
  import selectStore from '@/store/modules/global/filters/select';
  import singleSelect from '@/components/base/forms/single_select';
  import multiSelect from '@/components/base/forms/multi_select';
  import radioSelect from '@/components/base/forms/radio_select';
  import DateRangeMagic from '@/components/filters/magicstore/DateRangeMagic';
  import NdcLookup from '@/components/filters/NdcLookup';
  import color from '@/assets/scss/colors_vars.scss';
  import VarRepTable from '@/components/variancereports/table';
  import { defaultDateRange } from '@/data';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';
  import { mapState } from 'vuex';

  export default {
    name: 'VarianceReports',
    components: {
      singleSelect,
      multiSelect,
      radioSelect,
      NdcLookup,
      VarRepTable,
      DateRangeMagic,
      multiSelectCheckboxDropdown
    },
    data() {
      return {
        color: color,
        // atOptions: ['Index', '340B', 'WAC'],
        atOptions: [
          {
            text: 'Index',
            value: 'GPO'
          },
          {
            text: '340B',
            value: '340B'
          },
          {
            text: 'WAC',
            value: 'WAC'
          }
        ],
        ctOptions: ['AHFS', 'USC'],
        dateRange: defaultDateRange,
        hospitalListReady: false,
        hospitalListOptions: [],
        defaultHospitalName: false,
        defaultHospitalId: false,
        readyToLoadTable: false
      };
    },
    computed: {
      ...mapState('auth', {
        selectedHospitalList: (state) => state.selectedHospitalList
      }),
      isOptions() {
        return this.$store.getters['auth/userDataSegments'];
      },
      isDefault() {
        return this.isOptions.length > 0 ? this.isOptions[0] : {};
      },
      needsAccountTypes() {
        return (
          this.$route.name === 'DrugPricing'
        );
      },
      needsHospitalSelector() {
        return this.hospitalListOptions.length >= 2;
      },
      hospitalSegmentDefault() {
        return [{
          text: this.defaultHospitalName,
          value: this.defaultHospitalId
        }];
      },
      hospitalSegment: {
        get() {
          return this.$store.getters['vr/hospital_segment/getValue'];
        },
        set(value) {
          this.$store.commit('auth/setSelectedHospitalList', value);
          return this.$store.commit('vr/hospital_segment/setValue', value);
        }
      },
      indexSegment: {
        get() {
          return this.$store.getters['vr/index_segment/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/index_segment/setValue', value);
        }
      },
      accountTypes: {
        get() {
          return this.$store.getters['vr/account_types/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/account_types/setValue', value);
        }
      },
      codeType: {
        get() {
          return this.$store.getters['vr/code_type/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/code_type/setValue', value);
        }
      },
      ndcLookup: {
        get() {
          return JSON.parse(this.$store.getters['vr/ndc_lookup/getValue']);
        },
        set(value) {
          // We need watchers to see this as "updated", even if it's being reset
          //  to the same value. Force it by adding a random number and then turning
          //  it into a string - the JSONing is important!
          value.rand = Math.random();
          return this.$store.commit(
            'vr/ndc_lookup/setValue',
            JSON.stringify(value)
          );
        }
      }
    },
    methods: {
      hospitalList() {
        this.$store
          .dispatch('auth/getHospitalsForPermission', {
            subject: this.$route.meta.permission.subject,
            action: this.$route.meta.permission.action
          })
          .then((hospitalList) => {
            if (hospitalList.length > 0) {
              let options = [];

              for (let i in hospitalList) {
                options.push({
                  text: hospitalList[i].name,
                  value: hospitalList[i].id
                });
              }

              this.hospitalListOptions = options;
              this.defaultHospitalName = options[0].text;
              this.defaultHospitalId = options[0].value;

              // Register the hospital module dynamically
              //    the rest of the modules are registered in created()
              if (!this.$store.hasModule('vr/hospital_segment')) {
                this.$store.registerModule('vr/hospital_segment', selectStore);

                // setting option from store
                let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
                if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
                  this.$store.commit('vr/hospital_segment/setValue', selectedHospitalList);
                } else {
                  this.$store.commit(
                    'vr/hospital_segment/setValue',
                    this.hospitalSegmentDefault
                  );
                }

              }
            }

            this.readyToLoadTable = true;
          });
      },
      selectClass(field) {
        if (this.needsHospitalSelector) {
          // four fields
          if (this.needsAccountTypes) {
            // Hospital selector
            if (field == 'dateRange') {
              return 'sm:col-span-12 md:col-span-6 lg:col-span-4 xxl:col-span-2 order-0';
            } else if (field == 'accountTypes') {
              return 'sm:col-span-12 md:col-span-6 lg:col-span-6 xxl:col-span-3 order-1';
            } else if (field == 'currentHospital') {
              return 'sm:col-span-12 md:col-span-8 lg:col-span-6 xxl:col-span-4 order-2';
            } else if (field == 'indexSegment') {
              return 'sm:col-span-12 md:col-span-4 lg:col-span-4 xxl:col-span-3 order-3';
            }
          }
          // three fields
          else {
            if (field == 'dateRange') {
              return 'sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 order-0';
            } else if (field == 'currentHospital') {
              return 'sm:col-span-12 md:col-span-5 lg:col-span-5 xl:col-span-6 order-1';
            } else if (field == 'indexSegment') {
              return 'sm:col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-3 order-2';
            }
          }
        } else {
          // three fields
          if (this.needsAccountTypes) {
            if (field == 'dateRange') {
              return 'sm:col-span-6 md:col-span-3 xl:col-span-4 order-0';
            } else if (field == 'accountTypes') {
              return 'sm:col-span-6 md:col-span-5 xl:col-span-4 order-1';
            } else if (field == 'indexSegment') {
              return 'sm:col-span-6 md:col-span-4 xl:col-span-4 order-2';
            }
          }
          // two fields
          else {
            if (field == 'dateRange') {
              return 'sm:col-span-4 order-0';
            } else if (field == 'indexSegment') {
              return 'sm:col-span-8 order-1';
            }
          }
        }
      }
    },
    watch: {
      selectedHospitalList(v) {
        if (v !== undefined && v !== null && v.length > 0) {
          this.hospitalSegment = v;
        }
      }
    },
    created() {
      const store = this.$store;

      this.hospitalList();

      // Register the new modules dynamically
      //    hospital_segment is registered in hospitalList()

      if (!store.hasModule('vr/index_segment')) {
        store.registerModule('vr/index_segment', selectStore);
        this.$store.commit('vr/index_segment/setValue', this.isDefault);
      }
      if (!store.hasModule('vr/account_types')) {
        store.registerModule('vr/account_types', selectStore);
        this.$store.commit('vr/account_types/setValue', [
          {
            text: 'Index',
            value: 'GPO'
          }
        ]);
      }
      if (!store.hasModule('vr/code_type')) {
        store.registerModule('vr/code_type', selectStore);
        // default
        store.commit('vr/code_type/setValue', 'AHFS');
      }
      if (!store.hasModule('vr/ndc_lookup')) {
        store.registerModule('vr/ndc_lookup', selectStore);
      }
    },
    beforeDestroy() {
      // Unregister the dynamically created module
      if (this.$store.hasModule('vr/hospital_segment')) {
        this.$store.unregisterModule('vr/hospital_segment');
      }
      if (this.$store.hasModule('vr/index_segment')) {
        this.$store.unregisterModule('vr/index_segment');
      }
      if (this.$store.hasModule('vr/account_type')) {
        this.$store.unregisterModule('vr/account_type');
      }
      if (this.$store.hasModule('vr/date_range')) {
        this.$store.unregisterModule('vr/date_range');
      }
      if (this.$store.hasModule('vr/code_type')) {
        this.$store.unregisterModule('vr/code_type');
      }
      if (this.$store.hasModule('vr/ndc_lookup')) {
        this.$store.unregisterModule('vr/ndc_lookup');
      }
    }
  };
</script>
